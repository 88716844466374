<template>
  <div>
    <!-- Image -->
    <div class="flex-none h-auto w-[80px]">
      <img
        class="w-full h-full mr-4 rounded-lg shadow-md"
        :alt="audioPlayerNewStore.currentAudio.title"
        :src="audioPlayerNewStore.currentAudio.logoUrlOverview"
      >
    </div>

    <!-- Text -->
    <div class="shrink ml-3.5 mr-4 overflow-hidden relative">
      <div class="after:w-1.5 after:bg-gradient-to-l after:from-secondary after:absolute after:top-0 after:h-full after:right-0">
        <div class="flex flex-wrap">
          <p class="w-full text-sm font-medium text-white line-clamp-1 whitespace-nowrap">
            {{ audioPlayerNewStore.currentAudio.title }}
          </p>
          <p
            v-if="audioPlayerNewStore.currentAudio.type !== 'intro'"
            class="w-full text-xs line-clamp-1 text-dark-100 whitespace-nowrap"
          >
            {{ audioPlayerNewStore.currentAudio.type }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAudioPlayerNewStore } from '@/stores/audioPlayerNew'

const audioPlayerNewStore = useAudioPlayerNewStore()
</script>
